<template>
  <div class="row">
    <el-empty v-if="!isUpdate" description="Tính năng sẽ được cập nhật trong phiên bản sắp tới" class="mx-auto"></el-empty>
    <div class="content" v-else>
      <div class="col-xl-3"></div>
      <div class="col-xl-6">
        <el-card>
          <ul class="notification">
            <li class="unread cursor-pointer" v-for="(item,index) in notifications.data" :key="index"
                @click="tickRead(item)"
                v-bind:class="{ isRead:item.is_readed}">
              <div class="d-flex align-items-center show-child-on-hover">
                            <span class="d-flex flex-column flex-1">
                                <span class="name d-flex align-items-center">{{ item.title }} </span>
                                <span class="msg-a fs-sm"> {{ item.body }}
                                    <a href="intel_build_notes.html"></a>
                                </span>
                                <span class="fs-nano text-muted mt-1">{{ item.created_at | agoDate }}</span>
                            </span>
                <div class="show-on-hover-parent position-absolute pos-right pos-bottom p-3">
                  <!--                <a href="#" title="delete" class="text-muted">-->
                  <!--                  <i class="fal fa-trash-alt"></i>-->
                  <!--                </a>-->
                </div>
              </div>
            </li>
          </ul>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";

export default {
  name: "notification",
  data() {
    return {
      tabFeeds: true,
      tabCalendar: false,
      loading: false,
      isUpdate: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Xem tất cả thông báo", icon: 'fal fa-comment'}
    ]);
    // this.eventScroller();
  },
  methods: {
    eventScroller() {
      let that = this;
      window.onscroll = function () {
        if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) {
          that.setPage();
        }
      };
    },
    setPage() {
      let page_plus = this.page + 1;
      this.$store.dispatch(SET_PAGE, page_plus).then(() => {
        this.scrollNotification();
      });
    },
    scrollNotification() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(GET_NOTIFICATION, {
          page: this.page,
          limit: 20,
        }).then(() => {
          this.loading = false;
        });
      }
    },
    tickRead(item) {
      const id = item.id;
      if (item.is_readed) {
        this.redirectLink(item);
        return;
      }
      this.$store.dispatch(UPDATE_STATUS_READED, {
        id: id,
      }).then((response) => {
        if (!response.error) {
          this.notifications.data.filter((value) => {
            if (value.id == id) {
              return value.is_readed = response.data.is_readed
            }
          })
        }
      });
      this.redirectLink(item);
    },
    redirectLink(item) {
      if (item.flag_old) {
        // Case : cập nhật phiếu lương bên educenter.
        if (item.type == 1) {
          window.location = this.links[item.type];
        } else {
          if (item.url) window.location = item.url;
        }
      } else window.location = this.links[item.type];
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUser"]),
  },
}
</script>

<style>
.isRead {
  background: #ffffff !important;
}
</style>
